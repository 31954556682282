import { apiServiceForWSS3Bucket } from './ApiService';

const getJSONData = async (url: string) => {
  return apiServiceForWSS3Bucket.get(url).then((response) => {
    return response;
  });
};

export const commonService = {
  getJSONData,
};
